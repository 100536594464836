<template>
  <div class="compare">
    <tabBar :cardIndex="1" :activeIndexsa="0" />
		<div class="headers">
			<div class="headea">
				<div v-for="(item, index) in list" :key="index"
				class="tips" 
				:class="filg == index ? 'active_tip' : ''" 
				@click="qies(index)">{{ item.name }}</div>
			</div>
		</div>
    <div class="comparecenter" v-if="filg == 0">
      <div class="compare_centers">
        <div class="compare_left">
          <ul class="list_ul">
            <li v-for="(items, index) in datas" class="list-item" :key="index" @click="seedet(items.id)">
              <div class="litop">
                <img class="heder_img" src="../assets/img/touxing101.png" alt="" />
                <span class="topsapn">美国皓齿</span>
              </div>
              <div class="licenter">
                <img class="licentimg" :src="items.img" />
                <div class="licext_right">
                  <div class="righttop">{{ items.title }}</div>
                  <div class="rightbottom" v-html="items.content"></div>
                </div>
              </div>
              <div class="libottom">
                <div class="btncard">
                  <img class="imgs" src="../assets/img/biaoqian5627.png" alt="" />
                  {{ items.labelName }}
                </div>
                <div class="caopzuo">
                  <div class="allcss liulan">{{ items.viewNum }}浏览</div>
                  <div class="allcss pinglun" @click.stop="comments(items, index)">
                    <img class="imgs" src="../assets/img/xie/pinglun.png" alt="" />
										{{ items.replyNum }}
                  </div>
                  <div class="allcss dainzan" @click.stop="dianzans(items.id, index, !items.isDoUp)">
                    <img class="imgs" v-if="items.isDoUp" src="../assets/img/4509.png" alt="" />
                    <img class="imgs" v-if="!items.isDoUp" src="../assets/img/xie/zan.png" alt="" />
										<span :style="items.isDoUp ? 'color:#f26034' : ''">{{ items.giveNum }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="noalls" v-if="istotals">{{tips}}</div>
        </div>
      </div>
    </div>
    <div class="content" v-if="filg == 1">
      <div class="contents">
        <div class="cont_left">
          <div class="contforum" v-for="(item, index) in datas" :key="index">
            <div class="conthed">
            	<img :src='item.headImg' v-if="item.headImg" />
            	<img src='@/assets/img/user.png' v-else />
            	<span>{{item.nickName}}</span>
            </div>
            <div @click="seedet(item.id)">
            	<div class="contentxx" v-if="item.content">{{item.content}}</div>
            	<div v-if="item.contentImgs" style="margin-top: 15px;">
            		<div class="cont_img" v-if="item.contentImgs.length>1">
            			<div :style="{backgroundImage:'url('+ites+')'}" v-for="(ites,ind) in item.contentImgs" :key='ind'></div>
            		</div>
            		<div class="cont_imgs" v-else>
            			<img :src="ites" v-for="(ites,ind) in item.contentImgs" :key='ind' />
            		</div>
            	</div>
            	<div v-if="item.remarks" style="margin-bottom: 30px;margin-top: 20px;">
            		<video :src='item.remarks' style="width: 400px;" controls></video>
            	</div>
            </div>
            <div class="cont_boot">
              <div>{{ item.createTime }}</div>
              <div class="cont_boots">
                <div style="margin-right: 37px; cursor: pointer" @click="report(item)">
                  举报
                </div>
                <div style="margin-right: 31px">{{ item.viewNum }}浏览</div>
                <div class="cont_pinl" style="margin-right: 29px" @click="comments(item, index)">
                  <img style="height: 18px; width: 19px" src="../assets/img/xie/pinglun.png"/>
                  <span>{{ item.replyNum }}</span>
                </div>
                <div class="cont_pinl" @click="dianzans(item.id, index, !item.isDoUp)">
                  <img style="height: 18px; width: 18px" v-if="!item.isDoUp" src="../assets/img/xie/zan.png" />
                  <img style="height: 18px; width: 18px" v-else src="../assets/img/4509.png" />
                  <span :style="item.isDoUp ? 'color:#f26034' : ''">{{ item.giveNum }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="noalls" v-if="istotals">{{tips}}</div>
        </div>
      </div>
    </div>
		<div class="heads" v-if="topesa" @click="tops">
		  <i class="el-icon-arrow-up" style="font-size: 30px; color: #acacac"></i>
		</div>
		<div class="dilogs">
			<el-dialog :title="title" :visible.sync="dialogVisiblead" :show-close="false" width="30%">
			  <!-- <div>
			    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容" v-model="content">
			    </el-input>
			  </div> -->
				<div v-if="release==1">
					<div class="dilog_a">
						<div class="dils_labe">举报原因：</div>
						<el-select v-model="value" placeholder="请选择" style="width: 400px;" @change="selects">
							<el-option value="内容涉及色情暴力"></el-option>
							<el-option value="内容涉及欺诈造假"></el-option>
							<el-option value="内容涉及涉及违禁商品或服务"></el-option>
							<el-option value="感觉被冒犯或被歧视"></el-option>
							<el-option value="涉及侵权问题"></el-option>
							<el-option value="内容宣扬仇恨"></el-option>
							<el-option value="内容涉及垃圾营销"></el-option>
							<el-option value="人身攻击"></el-option>
							<el-option value="其他"></el-option>
						</el-select>
					</div>
					<div class="dilog_a" v-if="inputs">
						<div class="dils_labe">其他原因：</div>
						<el-input v-model="input" placeholder="请输入内容" type='textarea' :maxlength='500' :rows="4" show-word-limit style="width: 400px;"></el-input>
					</div>
				</div>
				<div v-if="release==2">
					<el-input v-model="comcont.content" type='textarea' maxlength="500" :rows='5' show-word-limit placeholder="请输入内容"></el-input>
				</div>
			  <div slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisiblead = false">取 消</el-button>
					<el-button v-if="release==1" type="primary" @click="reporda">确 定</el-button>
					<el-button v-else-if="release==2" type="primary" @click="dialogVisibleOkes">确 定</el-button>
			  </div>
			</el-dialog>
		</div>
    <footers></footers>
  </div>
</template>

<script>
// @ is an alias to /src
import tabBar from "@/components/tabBar.vue";
import footers from "@/components/footer.vue";
import { getforumLsit, putaddUp, postforumReply } from "../request/homeApi";
import { reportforum } from "@/request/xie";

export default {
  name: "Home",
  components: {
    tabBar,
    footers,
  },
  data() {
    return {
      dialogVisiblead: false,
      istotals: false,
      filg: 0,
      datas: [],
      params: {
        pageNumber: 1,
        pageSize: 10,
        keyWords: "",
        type: 0,
      },
			title:'评论',
      list: [
        {
          name: "博客",
          img: require("../assets/img/xie/boke.png"),
          imgs: require("../assets/img/xie/boke2.png"),
        },
        {
          name: "论坛",
          img: require("../assets/img/xie/luntan2.png"),
          imgs: require("../assets/img/xie/luntan.png"),
        },
      ],
      content: "",
			comcont:{  //评论参数
				forumId: '',
				content: '',
			},
			value:"",
			input:'',
      textid: "",
      textindex: null,
      tokens: "",
			topesa:false,
			total:'',
			tips:'没有更多了',
			inputs:false,
			release:1,
			repordata:{  //举报参数
				reasonName:'',
				forumId:''
			},
    };
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.tokens = localStorage.getItem("token");
    }
    this.lsitad();
		addEventListener('scroll', this.handleScroll)//监听函数
  },
	beforeDestroy() {
		window.removeEventListener("scroll",this.handleScroll)
	},
  methods: {
		//加载更多
		handleScroll(){
			//变量scrollTop是滚动条滚动时，距离顶部的距离
		 let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
		 if(scrollTop>1200){
			 this.topesa=true
		 }else{
			 this.topesa=false
		 }
		 //变量windowHeight是可视区的高度
		 let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
		 //变量scrollHeight是滚动条的总高度
		 let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
			//滚动条到底部的条件
			if(scrollTop+windowHeight == scrollHeight){
			 //你想做的事情
			 if(this.params.pageNumber<=this.total){
				 this.params.pageNumber=this.params.pageNumber+1
				 this.lsitad()
			 }
			}
		},
		//打开评论弹框
		comments(item,ind){
			if(!this.tokens){
				this.$message.error('请登录之后再操作')
				return false
			}
			this.title='评论'
			this.release=2
			this.comcont.content=''
			this.comcont.forumId=item.id
			this.textindex=ind
			this.dialogVisiblead=true
		},
		//提交评论
		dialogVisibleOkes() {
			if(this.comcont.content==''){
				this.$message.error('请填写评论内容')
				return false
			}
			//console.log(this.comcont)
		  postforumReply(this.comcont).then((res) => {
				if (res.code == 200) {
					this.dialogVisiblead = false;
					this.datas[this.textindex].replyNum = Number(this.datas[this.textindex].replyNum) + 1;
					this.$forceUpdate();
					this.$message.success("评论成功！");
				}
			})
		},
    // 点赞
    dianzans(id, index, isdianzan) {
      if (!this.tokens) {
        this.$message.error("请登录之后再操作");
        return false;
      }
      putaddUp(id, isdianzan).then((res) => {
          if (res.code == 200) {
            if (isdianzan) {
              this.datas[index].isDoUp = true;
              this.datas[index].giveNum = Number(this.datas[index].giveNum) + 1;
            } else {
              this.datas[index].isDoUp = false;
              this.datas[index].giveNum = Number(this.datas[index].giveNum) - 1;
            }
            this.$message.success("操作成功！");
          }
        })
    },
		//跳转详情
		seedet(ids) {
			if(this.filg==1){
				this.$router.push({
				  path: "forumdet",
				  query: { id: ids },
				});
			}else{
				this.$router.push({
				  path: "blogsdetail",
				  query: { id: ids },
				});
			}
		},
    //切换
    qies(ind, ids) {
      this.params.pageNumber = 1;
      this.params.type = ind;
      this.datas = [];
      if (ind == 0) {
        this.lsitad();
      }
      if (ind == 1) {
        this.lsitad();
      }
      this.$nextTick(() => {
        this.filg = ind;
      });
    },
		//获取数据
    lsitad() {
      this.params.keyWords = this.$route.query.sheracname;
      getforumLsit(this.params).then((res) => {
				if (res.code == 200) {
					this.total=res.result.list.pages
					res.result.list.records.forEach((item,index)=>{
						this.datas.push(item);
					})
					if (this.datas.length == res.result.list.total) {
					  this.istotals = true;
					}
					res.result.list.records.forEach((item) => {
						if(this.filg==1){
							if (item.contentImg) {
								let sk = item.contentImg.split(",");
								item.contentImgs = sk;
							}
						}else{
							if (item.content) {
								let sk = item.content.replace(/<img.*>.*<\/img>/gi, "");
								sk = sk.replace(/<img.*\/>/gi, "");
								item.content = sk;
							}
						}
					});
					//this.isAchiveBottom = false;
				}
			})
    },
		//回到顶部
		tops(){
			document.documentElement.scrollTop=0
		},
		//打开举报弹框
		report(item){
			if(!this.tokens){
				this.$message.error('请登录之后再操作')
				return false
			}
			this.title='举报'
			this.release=1
			this.value=''
			this.input=''
			this.repordata.forumId=item.id
			this.inputs=false
			this.dialogVisiblead=true
		},
		//举报原因
		selects(val){
			if(val=='其他'){
				this.inputs=true
			}else{
				this.inputs=false
			}
		},
		//举报
		reporda(){
			if(!this.inputs&&this.value==''){
				this.$message.error('请选择举报原因')
				return false
			}
			if(this.inputs&&this.input==''){
				this.$message.error('请填写举报原因')
				return false
			}
			if(this.inputs){
				this.repordata.reasonName=this.input
			}else{
				this.repordata.reasonName=this.value
			}
			reportforum(this.repordata).then((res)=>{
				if(res.code==200){
					this.$message.success('举报成功')
				}
				this.dialogVisiblead=false
			})
		},
  },
};
</script>
<style scoped lang="scss">
.headers {
  display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	margin: 0 auto;
	color: #535353;
	font-size: 18px;
	.headea{
		display: flex;
		align-items: center;
		width: 850px;
		.tips{
			margin-right: 20px;
			cursor: pointer;
		}
		.active_tip {
		  //color: #00a3e0;
		   border-bottom: 2px solid #00a3e0;
		}
	}
}
.compare {
  background-color: #f7f7f7;
	padding-top: 85px;
}
.content {
  display: flex;
  flex-flow: row;
  justify-content: center;
  background-color: #f7f7f7;
  padding-top: 20px;
  padding-bottom: 39px;
  box-sizing: border-box;
  .contents {
    display: flex;
    width: 850px;
  }
  .heads {
    width: 46px;
    height: 46px;
    background-color: #fff;
    border-radius: 50%;
    position: fixed;
    border: 1px solid #e2e2e2;
    bottom: 48px;
    cursor: pointer;
    right: 457px;
    text-align: center;
    line-height: 50px;
  }
  .cont_left {
    width: 850px;
    background-color: #fff;
    margin-right: 12px;
    padding: 0 22px 20px 22px;
    box-sizing: border-box;
    .contforum {
      margin-top: 26px;
      padding-bottom: 33px;
      border-bottom: 1px solid #e1e1e1;
      .conthed {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #535353;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 11px;
        }
      }
      .contentxx {
        color: #535353;
        font-size: 18px;
        margin-top: 26px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .cont_img {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 17px;
        div {
          width: 255px;
          height: 255px;
          background-size: cover;
          margin-bottom: 13px;
        }
      }
      .cont_img:after {
        content: "";
        width: 33.33%;
      }
      .cont_imgs {
        margin-top: 5px;
        img {
          width: 290px;
        }
      }
      .cont_boot {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        color: #999999;
        margin-top: 30px;
        font-size: 14px;
      }
      .cont_boots {
        display: flex;
        font-size: 16px;
        align-items: center;
        .cont_pinl {
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 6px;
          }
        }
      }
    }
    .noalls {
      text-align: center;
      padding-top: 20px;
      font-size: 16px;
      color: #999999;
    }
  }
  .cont_rigth {
    width: 323px;
    height: 200px;
    background-color: #fff;
    position: fixed;
    right: 448px;
    padding: 20px;
    box-sizing: border-box;
    color: #535353;
    font-size: 18px;
    .fabu {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 43px;
        height: 44px;
        margin-bottom: 12px;
      }
      span {
        color: #999999;
        font-size: 16px;
      }
    }
  }
  .actv_contrig {
    width: 323px;
    height: 200px;
    background-color: #fff;
    right: 448px;
    padding: 20px;
    box-sizing: border-box;
    color: #535353;
    font-size: 18px;
    .fabu {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 43px;
        height: 44px;
        margin-bottom: 12px;
      }
      span {
        color: #999999;
        font-size: 16px;
      }
    }
  }
}
.comparecenter {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  .compare_centers {
    width: 850px;
    height: 100%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    .compare_left {
      width: 100%;
      background: #ffffff;
      .list_ul {
        width: 100%;
        padding: 0 22px;
        overflow: hidden;
        .list-item {
          width: 100%;
          border-bottom: 1px solid #e1e1e1;
          cursor: pointer;
          .litop {
            display: flex;
            align-items: center;
            margin-top: 26px;
            .heder_img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 12px;
            }
            .topsapn {
              font-size: 16px;
              color: #535353;
            }
          }
          .licenter {
            display: flex;
            align-items: center;
            margin-top: 27px;
            .licentimg {
              width: 198px;
              height: 110px;
              margin-right: 12px;
              background-size: contain;
              background-position: center;
            }
            .licext_right {
              width: 65%;
              height: 110px;

              .righttop {
                width: 100%;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
                font-size: 18px;
                color: #535353;
              }
              ::v-deep .rightbottom {
                margin-top: 13px;
                width: 100%;
                height: 70px;
                font-size: 16px;
                color: #999999;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                p {
                  margin: 0;
                }
              }
            }
          }
          .libottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btncard {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px 16px;
              cursor: pointer;
              // line-height: 30px;
              background: #f0f9ff;
              border-radius: 15px;
              color: #00a3e0;
              .imgs {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }
            .caopzuo {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin: 30px 0 25px;
              .allcss {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #999999;
                cursor: pointer;
                .imgs {
                  width: 19px;
                  height: 18px;
                  margin-right: 6px;
                }
              }
              .pinglun {
                margin: 0 30px;
              }
            }
          }
        }
      }
    }
    .compare_right {
      width: 323px;
      display: inline-block;
      padding: 20px 18px;
      margin-left: 12px;
      background: #ffffff;
      .titiles {
        font-size: 18px;
        color: #535353;
      }
      .rights {
        .rigtlist {
          margin-top: 25px;
          width: 100%;
          padding-bottom: 20px;
          border-bottom: 1px solid #e1e1e1;
          .imgsa {
            width: 100%;
            height: 165px;
          }
          .texttitle {
            width: 100%;
            font-size: 18px;
            color: #535353;
            margin-top: 15px;
          }
          .texts {
            margin-top: 12px;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            font-size: 16px;
            color: #999999;
            ::v-deep p {
              margin: 0;
            }
          }
        }
        .rigtlist:last-child {
          border: none;
        }
      }
    }
  }
}
.heads{
	width: 46px;
	height: 46px;
	background-color: #fff;
	border-radius: 50%;
	position: fixed;
	border: 1px solid #E2E2E2;
	bottom: 48px;
	cursor: pointer;
	right: 300px;
	text-align: center;
	line-height: 50px;
}
.noalls {
  text-align: center;
  padding: 10px 0 20px;
  font-size: 16px;
  color: #999999;
}
::v-deep .dilogs{
	.el-dialog__header{
		padding: 0 0 0 20px;
		height: 50px;
		line-height: 50px;
		box-sizing: border-box;
		border-radius: 4px 4px 0px 0px;
		font-size: 16px;
		color: #535353;
		background-color: #F8F9FB;
		button{
			display: none;
		}
	}
	.el-dialog__body{
		padding: 22px 25px 22px 27px;
		box-sizing: border-box;
	}
	.dialog-footer{
		display: inline-block;
		width: 100%;
		display: flex;
		flex-flow: row;
		justify-content: center;
		button:first-child{
			background-color: #97999B;
			color: #fff;
		}
	}
	.dilog_a{
		display: flex;
		margin-bottom: 25px;
		.dils_labe{
			width: 100px;
			text-align: right;
		}
	}
	.dilog_rel{
		.el-textarea__inner{
			background-color: #F8F9FB;
			border-color: #F8F9FB;
		}
		.formats{
			width: 40px;
			height: 40px;
			display: flex;
			flex-flow: row;
			justify-content: center;
			align-items: center;
			margin-right: 14px;
			border-radius: 50%;
			img{
				cursor: pointer;
				height: 24px;
			}
		}
		.activeform{
			background-color: #95999C;
		}
	}
}
</style>